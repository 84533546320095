import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  HttpClientInterceptor
} from '@interceptors/http-client-interceptor/http-client-interceptor';
import { CommentRequest, UserComment } from '@interfaces/comment.interface';
import {
  FormDataRequest, PaperworkQuery, PartialRequestLoadResponse, PartialRequestSaveResponse,
  StatusResponse
} from '@interfaces/forms.interface';
import {
  FormRequestStatusDetail, PartialFormRequestDetailRequest
} from '@interfaces/partial-form.interface';
import { DeletePaperworkFilesRequest } from '@interfaces/requests.interface';
import { GET_PAPERWORK_MOCK } from '@services/cms/mocks/paperwork-follow-up.mock';
import { ErrorUtils } from '@utils/error.utils';

import { GET_COMMENTS_DATA_MOCK } from './mocks/commets.mock';
import { EMPTY_MOCK, FORM_DATA_MOCK, PARTIAL_SAVE_MOCK } from './mocks/forms.mock';
import { PARTIAL_FORM_MOCKUP } from './mocks/partial-forms.mock';

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  private baseUrl = `${environment.affiliateApplicationsUrl}forms`;
  private partialBaseUrl = `${environment.affiliateApplicationsUrl}partial-forms`;
  private discountBaseUrl = `${environment.affiliateApplicationsUrl}discount-form/load`;

  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils,
  ) { }

  public submitSurvivalPensionForm(params: FormData): Observable<any> {
    const url = `${this.baseUrl}/pensionaries/survival`;
    return this.http.post(url, params, EMPTY_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public submitFamilyAllowanceForm(params: FormData): Observable<any> {
    const url = `${this.baseUrl}/family-allowance`;
    return this.http.post(url, params, EMPTY_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public submitStudiesAccreditationForm(params: FormData): Observable<any> {
    const url = `${this.baseUrl}/studies-accreditation`;
    return this.http.post(url, params, EMPTY_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public submitMortuaryFeeForm(params: FormData): Observable<any> {
    const url = `${this.baseUrl}/mortuary-fee`;
    return this.http.post(url, params, EMPTY_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public submitChargingFeesForm(params: FormData): Observable<any> {
    const url = `${this.baseUrl}/charging-fees`;
    return this.http.post(url, params, EMPTY_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public submitStateGuaranteeForm(params: FormData): Observable<any> {
    const url = `${this.baseUrl}/state-guarantee`;
    return this.http.post(url, params, EMPTY_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public submitInheritanceRequestForm(params: FormData): Observable<any> {
    const url = `${this.baseUrl}/inheritance`;
    return this.http.post(url, params, EMPTY_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public submitPartialForm(request: FormData): Observable<PartialRequestSaveResponse> {
    const url = `${this.partialBaseUrl}/save`;
    return this.http.post(url, request, PARTIAL_SAVE_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getFormData(params: FormDataRequest): Observable<PartialRequestLoadResponse> {
    const url = `${this.partialBaseUrl}/load`;
    return this.http.get(url, FORM_DATA_MOCK[params.type], params)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public loadPaperwork(queryPaperwork: PaperworkQuery): Observable<StatusResponse> {
    const { rut, email, phone } = queryPaperwork;
    const url = `${this.partialBaseUrl}/public/v2/paperwork-status`;
    return this.http.get(url, GET_PAPERWORK_MOCK, { rut, email, phone })
      .pipe(
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getStatusDetail(request: PartialFormRequestDetailRequest): Observable<FormRequestStatusDetail> {
    const url = `${this.partialBaseUrl}/v2/detail`;
    return this.http.get(url, PARTIAL_FORM_MOCKUP, request);
  }

  public uploadPaperworkFile(formData: FormData): Promise<void> {
    const url = `${this.partialBaseUrl}/v2/paperwork-files`;
    return this.http.post(url, formData, EMPTY_MOCK).pipe(take(1)).toPromise() as Promise<void>;
  }

  public deletePaperworkFile(queryPaperworkFiles: DeletePaperworkFilesRequest): Promise<void> {
    const url = `${this.partialBaseUrl}/v2/paperwork-files`;
    return this.http.delete(url, EMPTY_MOCK, queryPaperworkFiles).pipe(take(1)).toPromise() as Promise<void>;
  }

  public getComments(requestInformationUuid: string): Promise<Array<UserComment>> {
    const url = `${this.partialBaseUrl}/v2/comments`;
    return this.http.get(url, GET_COMMENTS_DATA_MOCK, { requestInformationUuid })
      .pipe(take(1)).toPromise();
  }

  public saveComment(comment: CommentRequest): Promise<void> {
    const url = `${this.partialBaseUrl}/v2/comments`;
    return this.http.post(url, comment, null).pipe(take(1)).toPromise();
  }

  public submitMandateForm(formData: FormData): Promise<void> {
    const url = `${this.baseUrl}/mandates`;
    return this.http.post(url, formData, EMPTY_MOCK).pipe(take(1)).toPromise() as Promise<void>;
  }

  public submitTransferSurvivorPensionBeneficiariesForm(formData: FormData): Promise<void> {
    const url = `${this.baseUrl}/transfer-survivor-pension-beneficiaries`;
    return this.http.post(url, formData, EMPTY_MOCK).pipe(take(1)).toPromise() as Promise<void>;
  }

  public getFormsEmployerDiscount(params: { type: string; }): Observable<any> {
    const url = `${environment.affiliateApplicationsUrl}resources/authorization`;
    return this.http.get(url, null, params);
  }
  public getFormDataLastIngress(params: FormDataRequest): Observable<PartialRequestLoadResponse> {
    const url = `${this.partialBaseUrl}/get-load-last-form-ingress`;

    return this.http.get(url, FORM_DATA_MOCK[params.type], params)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getCertified(formData: FormData): Observable<any> {
    const url = `${this.baseUrl}/employer-discount-for-voluntary-affiliate`;
    return this.http.post(url, formData, EMPTY_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getDiscountData(params: FormDataRequest): Observable<PartialRequestLoadResponse> {
    const url = `${this.discountBaseUrl}`;
    return this.http.get(url, null, params)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }
}
