import { Banner } from '@interfaces/banner.interface';
import { CardArticlesContainer } from '@interfaces/components.interface';
import { DownloadSection, PdfFile, Section } from '@interfaces/general.interface';
import { Translation } from './cms.interface';
import { FaqSection } from './faq-section.interface';
import { FaqId } from './savings.interface';

export type Role = 'employer' | 'employee';

export interface FamilyAllowanceItem {
  id: number;
  banner: number;
  asignacion_familiar_seccion: Array<FamilyAllowanceSectionItem>;
  topes_mensuales: Array<FamilyAllowanceSectionItem>;
}

export interface BannerItem {
  id: number;
}

export interface FamilyAllowanceSectionItem extends Translation {
  id: number;
  texto_boton: string;
  url_boton: string;
  tabla: { [key: string]: string; };
  asignacion_familiar: number;
  titulo: string;
  descripcion: string;
}

export interface CurrentCommissionsValuesItem {
  comision_afp_dependiente: string;
  comision_afp_independientes: string;
  seguro_invalidez_independientes: string;
  total_independiente: string;
  comision_afp_independientes_mujer: string;
  seguro_invalidez_independientes_mujer: string;
  total_independiente_mujer: string;
  comision_afp_pensionados: string;
  traspaso_entre_fondos: string;
  retiros_programados_renta_temp: string;
  admin_cav: string;
  aportes_indemnizacion: string;
  admin_apv_afiliado: string;
  admin_apv_no_afiliado: string;
  transf_apv: string;
  transf_apv_colectivo: string;
  transf_cav: string;
  fecha_actualizacion: string;
}

export interface EmployerHome {
  banners: Array<Banner>;
  articlesSection: CardArticlesContainer;
}

export interface Password {
  banner: Banner;
  getPasswordSection: Section;
  getCertificateSection: Section;
  requestPasswordSection: Section;
  faqSection: FaqSection;
}

export interface PasswordResponse {
  data: PasswordItem;
}

export interface PasswordItem {
  titulo_preguntas: string;
  descripcion_preguntas: string;
  preguntas: Array<FaqId>;
}

export interface PasswordMapped {
  banner: Banner;
  faqSection: FaqSection;
}

export interface AgreedDeposits {
  banner: Banner;
  whatIsSection: Section;
}

export interface EmployeesTypes {
  banner: Banner;
  whatEmployeeSection: Section;
}

export interface YoungEmployeeSubsidy {
  banner: Banner;
  employee: string;
  employer: string;
}

export interface FamilyAllowance {
  banner: Banner;
  familyAllowanceSection: FamilyAllowanceSection;
  monthlyCaps: FamilyAllowanceSection;
}

export interface FamilyAllowanceSection extends Section {
  buttonLabel: string;
  buttonUrl: string;
  table: Array<FamilyAllowanceTable>;
}

export interface FamilyAllowanceTable {
  title: string;
  value: string;
}

export interface PaymentForm {
  banner: Banner;
  person: DownloadSection;
  employer: DownloadSection;
}

export interface PaymentOptions extends Section {
  banner: Banner;
  disclaimerBox: string;
  buttonLabel: string;
  buttonUrl: string;
}

export interface ExcessPayment extends Section {
  banner: Banner;
  buttonLabel: string;
  pdfFile: PdfFile;
}
