import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_METATAG, METATAGS } from '@constants/meta-tag.constant';
import { LANGUAGE_KEY } from '@constants/translate.constant';
import { environment } from '@environment';
import { AlertBanner } from '@interfaces/components.interface';
import { MetaTag } from '@interfaces/meta-tag.interface';
import { TranslateService } from '@ngx-translate/core';
import { LanguageProvider } from '@providers/language/language.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ComponentsService } from '@services/cms/components.service';
import * as moment from 'moment';
import { register } from 'swiper/element/bundle';

import { VideoCallService } from '@services/video-call/video-call.service';
import packageInfo from '../../package.json';

moment.locale('es-mx');

declare let fbq: Function;

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public showLoading = false;
  public alertBanner: AlertBanner;
  public showAlert: boolean;
  public loading = false;
  public vitaliaOptionsOpened = false;

  constructor(
    private router: Router,
    private componentsService: ComponentsService,
    private loadingService: LoadingService,
    private meta: Meta,
    private translate: TranslateService,
    private languageProvider: LanguageProvider,
    private titleService: Title,
    private renderer: Renderer2,
    private videocall: VideoCallService,
  ) {
    this.initializeLanguage();
    this.trackEvents();
    this.addRecaptchaScript();
    this.addScreenshareScript();
    this.addWebChatScript();
    this.meta.addTag({ name: 'Version', content: packageInfo.version });
  }

  ngOnDestroy(): void {
    this.videocall.endVideocall();
  }

  public ngOnInit(): void {
    this.getAlertBanner();
    this.languageSubscription();
    this.loadingService.getLoadingRequestIntercepted().subscribe((showLoading) => setTimeout(() => this.showLoading = showLoading, 0));
    this.videocall.auviousScript();
  }

  private languageSubscription(): void {
    this.languageProvider.language.subscribe(() => this.getAlertBanner());
  }

  public setShowAlert(hideAlert?: boolean): void {
    this.showAlert = this.alertBanner && this.alertBanner.active && !hideAlert;
  }

  public getAlertBanner(): void {
    this.loading = true;
    this.componentsService.getAlertBanner().toPromise()
      .then((response) => {

        this.alertBanner = response;
        this.setShowAlert();
      })
      .finally(() => this.loading = false);
  }

  private initializeLanguage(): void {
    if (localStorage.getItem(LANGUAGE_KEY) !== null) {
      this.translate.use(localStorage.getItem(LANGUAGE_KEY));
    }
  }

  private trackEvents(): void {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        fbq('track', 'PageView');
        const metaTag = METATAGS.find(meta => this.router.url.includes(meta.url));
        this.changeMetaTags(metaTag || DEFAULT_METATAG);
      }
    });
  }

  private changeMetaTags(metatag: MetaTag): void {
    this.titleService.setTitle(metatag.title);
    metatag.metas.forEach(meta => this.meta.updateTag(meta));
  }

  private addRecaptchaScript(): void {
    const recaptchaSrc = (document: any, s: string, id: string, obj: any) => {
      // tslint:disable-next-line: prefer-const
      let js: any;
      const fjs = document.getElementsByTagName(s)[0];
      if (document.getElementById(id)) { return; }
      // eslint-disable-next-line prefer-const
      js = document.createElement(s); js.id = id;
      js.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.recaptchaSiteKey}`;
      fjs.parentNode.insertBefore(js, fjs);
    };
    recaptchaSrc(document, 'script', 'recaptcha-jssdk', this);
  }

  private addScreenshareScript() {
    const screenshareSrc = (document: any, s: string, id: string, obj: any) => {
      // tslint:disable-next-line: prefer-const
      let js: any;
      const fjs = document.getElementsByTagName(s)[0];
      if (document.getElementById(id)) { return; }
      // eslint-disable-next-line prefer-const
      js = document.createElement(s); js.id = id;
      js.src = `https://sdk-cdn.mypurecloud.com/webrtc-sdk/genesys-cloud-webrtc-sdk.bundle.min.js`;
      js.type = 'text/javascript';
      fjs.parentNode.insertBefore(js, fjs);
    };
    screenshareSrc(document, 'script', 'screenshare-jssdk', this);
  }

  private addWebChatScript() {

    const script = this.renderer.createElement('script');
    script.innerHTML = `
       (function (g, e, n, es, ys) {
        g['_genesysJs'] = e;
        g[e] = g[e] || function () {
          (g[e].q = g[e].q || []).push(arguments)
        };
        g[e].t = 1 * new Date();
        g[e].c = es;
        ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
      })(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
        environment: 'prod',
        deploymentId: 'c3bd71da-f7b1-4e07-bcd1-d735c04326fb'
      });
    `;
    this.renderer.appendChild(document.head, script);
  }

  public menuStatus(val: boolean) {
    this.vitaliaOptionsOpened = val;
  }
}

