import { Component, OnInit } from '@angular/core';
import {
  CORPORATE_GOVERNANCE_CONTENT
} from '@constants/pages-content/corporate-governance.constant';
import { POLICIES_STATUTES_BREADCRUMBS } from '@constants/pages-content/corporation.constant';
import { POLICIES_TITLES } from '@constants/titles.constant';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-corporate-governance',
  templateUrl: './corporate-governance.page.html',
  styleUrls: ['./corporate-governance.page.scss'],
})
export class CorporateGovernancePage implements OnInit {
  public pageContent = CORPORATE_GOVERNANCE_CONTENT;
  public breadcrumb = [...POLICIES_STATUTES_BREADCRUMBS, POLICIES_TITLES.content.government];
  public loading = false;

  constructor(
    public font: FontService,
    private cmsService: CmsService,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit() {
      this.loadContent();
      this.languageSubscription();
  }

  private loadContent() {
    this.loading = true;
    this.cmsService.loadCorporateGovernanceValues().toPromise()
    .then(([{ topics, certificate }, faq, header]) => {
      this.pageContent.topics = topics;
      this.pageContent.certificate = certificate;
      this.pageContent.faq = faq;
      this.pageContent.header = header;
    })
    .finally(() => this.loading = false);
  }

  private languageSubscription() {
    this.languageProvider.language.subscribe(() => { this.loadContent();
    });
  }
}
