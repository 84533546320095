// tslint:disable:max-line-length
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AttachFilesComponent } from '@components/attach-files/attach-files.component';
import {
  FamilyAllowanceBackgroundComponent
} from '@components/family-allowance-background/family-allowance-background.component';
import { FormArrayListComponent } from '@components/form-array-list/form-array-list.component';
import { ModalInfoDownloadableFormatsComponent } from '@components/modal-info-downloadable-formats/modal-info-downloadable-formats.component';
import { ModalRutValidationComponent } from '@components/modal-rut-validation/modal-rut-validation.component';
import {
  SerialNumberModalComponent
} from '@components/serial-number-modal/serial-number-modal.component';
import { StepperComponent } from '@components/stepper/stepper.component';
import { VoucherComponent } from '@components/voucher/voucher.component';
import { DirectivesModule } from '@directives/directives.module';
import {
  EmployerInfoStepComponent
} from '@modules/affiliate/assessment/paperwork-center/cai/form/employer-info-step/employer-info-step.component';
import {
  PaymentMethodStepComponent
} from '@modules/affiliate/assessment/paperwork-center/cai/form/payment-method-step/payment-method-step.component';
import {
  PersonalInfoStepComponent
} from '@modules/affiliate/assessment/paperwork-center/cai/form/personal-info-step/personal-info-step.component';
import {
  ReasonStepComponent
} from '@modules/affiliate/assessment/paperwork-center/cai/form/reason-step/reason-step.component';
import {
  CertifyStudiesBackgroundsComponent
} from '@modules/pensioner/assessment/certify-studies/certify-studies-backgrounds/certify-studies-backgrounds.component';
import {
  CertifyStudiesDeclarationFileComponent
} from '@modules/pensioner/assessment/certify-studies/certify-studies-declaration-file/certify-studies-declaration-file.component';
import {
  CertifyStudiesDeclarationsComponent
} from '@modules/pensioner/assessment/certify-studies/certify-studies-declarations/certify-studies-declarations.component';
import {
  CertifyStudiesPaymentMethodComponent
} from '@modules/pensioner/assessment/certify-studies/certify-studies-payment-method/certify-studies-payment-method.component';
import {
  ChargingFeesBackgroundsComponent
} from '@modules/pensioner/assessment/charging-fees/charging-fees-backgrounds/charging-fees-backgrounds.component';
import {
  ChargingFeesDocumentationComponent
} from '@modules/pensioner/assessment/charging-fees/charging-fees-documentation/charging-fees-documentation.component';
import {
  InheritanceRequestAttachmentsComponent
} from '@modules/pensioner/assessment/inheritance-request/inheritance-request-attachments/inheritance-request-attachments.component';
import {
  InheritanceRequestBackgroundsComponent
} from '@modules/pensioner/assessment/inheritance-request/inheritance-request-backgrounds/inheritance-request-backgrounds.component';
import {
  InheritanceRequestReasonsComponent
} from '@modules/pensioner/assessment/inheritance-request/inheritance-request-reasons/inheritance-request-reasons.component';
import {
  StateGuaranteeBackgroundComponent
} from '@modules/pensioner/assessment/state-guarantee/state-guarantee-background/state-guarantee-background.component';
import {
  StateGuaranteeConditionsComponent
} from '@modules/pensioner/assessment/state-guarantee/state-guarantee-conditions/state-guarantee-conditions.component';
import {
  StateGuaranteeDeclarationsComponent
} from '@modules/pensioner/assessment/state-guarantee/state-guarantee-declarations/state-guarantee-declarations.component';
import { AccessibilityComponent } from './accessibility/accessibility.component';
import {
  AccordionDownloadListComponent
} from './accordion-download-list/accordion-download-list.component';
import { AccordionListComponent } from './accordion-list/accordion-list.component';
import { AccountFoundsComponent } from './account-founds/account-founds.component';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { AlertToastComponent } from './alert-toast/alert-toast.component';
import { AlertComponent } from './alert/alert.component';
import {
  ApplicationFormProgressComponent
} from './application-form-progress/application-form-progress.component';
import { AssistanceButtonComponent } from './assistance-button/assistance-button.component';
import { AssistanceInHoursComponent } from './assistance-in-hours/assistance-in-hours.component';
import { AssistanceOutHoursComponent } from './assistance-out-hours/assistance-out-hours.component';
import { AttachFilesPartialSaveComponent } from './attach-files-partialsave/attach-files-partialsave.component';
import { AttachmentsPaymentComponent } from './attachments-payment/attachments-payment.component';
import { AvatarComponent } from './avatar/avatar.component';
import { BannerComponent } from './banner/banner.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CalendarCardComponent } from './calendar-card/calendar-card.component';
import { CardArticleComponent } from './card-article/card-article.component';
import {
  CardAttachedDocumentsComponent
} from './card-attached-documents/card-attached-documents.component';
import { CardCommentsComponent } from './card-comments/card-comments.component';
import { CardComponent } from './card/card.component';
import { ChartInvestorProfileComponent } from './chart-investor-profile/chart-investor-profile.component';
import { ChartQuotaValuesComponent } from './chart-quota-values/chart-quota-values.component';
import { ChartistComponent } from './chartist/chartist.component';
import { CommentComponent } from './comment/comment.component';
import { ContactChannelsComponent } from './contact-channels/contact-channels.component';
import {
  DeceasedAffiliateBackgroundComponent
} from './deceased-affiliate-background/deceased-affiliate-background.component';
import {
  DeceasedAffiliatePaymentComponent
} from './deceased-affiliate-payment/deceased-affiliate-payment.component';
import {
  DeceasedBackgroundFormComponent
} from './deceased-background-form/deceased-background-form.component';
import { DisclaimerBoxComponent } from './disclaimer-box/disclaimer-box.component';
import {
  DisclaimerCommisionComponent
} from './disclaimer-commision/disclaimer-commision.component';
import { DisclaimerInfoComponent } from './disclaimer-info/disclaimer-info.component';
import { DownloadSectionComponent } from './download-section/download-section.component';
import { DropDownPeriodComponent } from './drop-down-period/drop-down-period.component';
import {
  FamilyAllowanceStatementComponent
} from './family-allowance-statement/family-allowance-statement.component';
import { FilterChipsComponent } from './filter-chips/filter-chips.component';
import { FooterComponent } from './footer/footer.component';
import { FormArrayListPartialSaveComponent } from './form-array-list-partialsave/form-array-list-partialsave.component';
import { FormStatusDetailComponent } from './form-status-detail/form-status-detail.component';
import { FundHelpPopoverComponent } from './fund-help-popover/fund-help-popover.component';
import { FundInfographicComponent } from './fund-infographic/fund-infographic.component';
import { FundQuotaValueComponent } from './fund-quota-value/fund-quota-value.component';
import {
  FuneralServicesBackgroundComponent
} from './funeral-services-background/funeral-services-background.component';
import { HeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { HeaderComponent } from './header/header.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { IconCardComponent } from './icon-card/icon-card.component';
import { InfoBannerComponent } from './info-banner/info-banner.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { InfoTextPopupComponent } from './info-text-popup/info-text-popup.component';
import { InfographicComponent } from './infographic/infographic.component';
import { InformationAlertComponent } from './information-alert/information-alert.component';
import { InvestorProfileBoxComponent } from './investor-profile-box/investor-profile-box.component';
import { InvestorResultPopoverComponent } from './investor-result-popover/investor-result-popover.component';
import { LoginComponent } from './login/login.component';
import { MainActionsComponent } from './main-actions/main-actions.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ModalAppStoresComponent } from './modal-app-stores/modal-app-stores.component';
import {
  ModalBankRegisterResultComponent
} from './modal-bank-register-result/modal-bank-register-result.component';
import { ModalBeneficiaryComponent } from './modal-beneficiary/modal-beneficiary.component';
import { ModalConfirmationComponent } from './modal-confirmation/modal-confirmation.component';
import { ModalContactCenterV2Component } from './modal-contact-center-v2/modal-contact-center-v2.component';
import { ModalContactCenterComponent } from './modal-contact-center/modal-contact-center.component';
import { ModalContactResultComponent } from './modal-contact-result/modal-contact-result.component';
import { ModalContactComponent } from './modal-contact/modal-contact.component';
import { ModalDefinitionsComponent } from './modal-definitions/modal-definitions.component';
import { ModalFamilyBurdenComponent } from './modal-family-burden/modal-family-burden.component';
import { ModalFileDownloadComponent } from './modal-file-download/modal-file-download.component';
import { ModalFilterChipsComponent } from './modal-filter-chips/modal-filter-chips.component';
import { ModalGenericComponent } from './modal-generic/modal-generic.component';
import {
  ModalGetCertificateComponent
} from './modal-get-certificate/modal-get-certificate.component';
import { ModalInformationComponent } from './modal-information/modal-information.component';
import { ModalIntroductoryComponent } from './modal-introductory/modal-introductory.component';
import {
  ModalInvestmentTableFundComponent
} from './modal-investment-table-fund/modal-investment-table-fund.component';
import { ModalLoginComponent } from './modal-login/modal-login.component';
import { ModalMandateComponent } from './modal-mandate/modal-mandate.component';
import { ModalPowerAttorneyComponent } from './modal-power-attorney/modal-power-attorney.component';
import { ModalPrimaryComponent } from './modal-primary/modal-primary.component';
import {
  MultiFundsAssignationComponent
} from './modal-primary/multi-funds-assignation/multi-funds-assignation.component';
import {
  MultiFundsProfitabilityComponent
} from './modal-primary/multi-funds-profitability/multi-funds-profitability.component';
import {
  MultiFundsRestrictionComponent
} from './modal-primary/multi-funds-restrictions/multi-funds-restrictions.component';
import { ModalStopScreenshareComponent } from './modal-stop-screenshare/modal-stop-screenshare.component';
import {
  ModalStudiesCertificateComponent
} from './modal-studies-certificate/modal-studies-certificate.component';
import {
  ModalSummaryQuotaValuesComponent
} from './modal-summary-quota-values/modal-summary-quota-values.component';
import {
  ModalTransferPensionOptionComponent
} from './modal-transfer-pension-option/modal-transfer-pension-option.component';
import { ModalTransferSurvivorPensionBeneficiariesComponent } from './modal-transfer-survivor-pension-beneficiaries/modal-transfer-survivor-pension-beneficiaries.component';
import {
  ModalValidateCertificateComponent
} from './modal-validate-certificate/modal-validate-certificate.component';
import { ModalComponent } from './modal/modal.component';
import { MoreInformationComponent } from './more-information/more-information.component';
import { MufflerComponent } from './muffler/muffler.component';
import { PaginatedTableComponent } from './paginated-table/paginated-table.component';
import { PaperworkFollowUpComponent } from './paperwork-follow-up/paperwork-follow-up.component';
import { PaymentMethodFormComponent } from './payment-method-form/payment-method-form.component';
import { PensionBackgroundsComponent } from './pension-backgrounds/pension-backgrounds.component';
import { PensionModesComponent } from './pension-modes/pension-modes.component';
import { PensionOffersComponent } from './pension-offers/pension-offers.component';
import { PensionTypesComponent } from './pension-types/pension-types.component';
import { PersonBackgroundsComponent } from './person-backgrounds/person-backgrounds.component';
import { PopoverHeaderMenuComponent } from './popover-header-menu/popover-header-menu.component';
import { PopoverInformationComponent } from './popover-information/popover-information.component';
import { PopoverOptionsComponent } from './popover-options/popover-options.component';
import { PopoverComponent } from './popover/popover.component';
import { QuotaValueSearcherComponent } from './quota-value-searcher/quota-value-searcher.component';
import { QuotaValuesBannerComponent } from './quota-values-banner/quota-values-banner.component';
import { QuotaValuesComponent } from './quota-values/quota-values.component';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import {
  RadioButtonListFormComponent
} from './radio-button-list-form/radio-button-list-form.component';
import { RegisterFormBoxComponent } from './register-form-box/register-form-box.component';
import {
  SearchPaperworkByFieldComponent
} from './search-paperwork-by-field/search-paperwork-by-field.component';
import { SecondaryArticlesComponent } from './secondary-articles/secondary-articles.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import {
  SliderCalendarCardsComponent
} from './slider-calendar-cards/slider-calendar-cards.component';
import { SliderNavigationComponent } from './slider-navigation/slider-navigation.component';
import { SlidesBannerComponent } from './slides-banner/slides-banner.component';
import { SlidesInfographicComponent } from './slides-infographic/slides-infographic.component';
import { SlidesProductsComponent } from './slides-products/slides-products.component';
import { SlidesComponent } from './slides/slides.component';
import { StepsComponent } from './steps/steps.component';
import { SuperintendenceComponent } from './superintendence/superintendence.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import {
  TransferPensionFormComponent
} from './transfer-pension-form/transfer-pension-form.component';
import { TransferPensionOptionComponent } from './transfer-pension-option/transfer-pension-option.component';
import { TransferStateBoxComponent } from './transfer-state-box/transfer-state-box.component';
import {
  ValidateCertificateBoxComponent
} from './validate-certificate-box/validate-certificate-box.component';
import { FaqSectionComponent } from './faq-section/faq-section.component';

@NgModule({
  declarations: [
    AccessibilityComponent,
    AccordionDownloadListComponent,
    AccordionListComponent,
    AlertBannerComponent,
    AlertComponent,
    AlertToastComponent,
    ApplicationFormProgressComponent,
    AvatarComponent,
    AttachFilesComponent,
    AttachFilesPartialSaveComponent,
    AttachmentsPaymentComponent,
    BannerComponent,
    BreadcrumbComponent,
    CardCommentsComponent,
    CardComponent,
    CalendarCardComponent,
    CardArticleComponent,
    CardAttachedDocumentsComponent,
    CertifyStudiesBackgroundsComponent,
    CertifyStudiesDeclarationFileComponent,
    CertifyStudiesDeclarationsComponent,
    CertifyStudiesPaymentMethodComponent,
    CommentComponent,
    ContactChannelsComponent,
    ChargingFeesBackgroundsComponent,
    ChargingFeesDocumentationComponent,
    ChartInvestorProfileComponent,
    ChartQuotaValuesComponent,
    ChartistComponent,
    DeceasedAffiliateBackgroundComponent,
    DeceasedAffiliatePaymentComponent,
    DeceasedBackgroundFormComponent,
    DisclaimerBoxComponent,
    DisclaimerInfoComponent,
    DisclaimerCommisionComponent,
    DownloadSectionComponent,
    EmployerInfoStepComponent,
    FamilyAllowanceBackgroundComponent,
    FamilyAllowanceStatementComponent,
    FaqSectionComponent,
    FilterChipsComponent,
    FooterComponent,
    FormArrayListComponent,
    FormArrayListPartialSaveComponent,
    FormStatusDetailComponent,
    FundHelpPopoverComponent,
    FundInfographicComponent,
    FundQuotaValueComponent,
    FuneralServicesBackgroundComponent,
    HeaderComponent,
    HeaderDesktopComponent,
    HeaderMobileComponent,
    HelpButtonComponent,
    IconCardComponent,
    InfoBannerComponent,
    InfoCardComponent,
    InfographicComponent,
    InformationAlertComponent,
    InheritanceRequestAttachmentsComponent,
    InheritanceRequestBackgroundsComponent,
    InheritanceRequestReasonsComponent,
    InvestorProfileBoxComponent,
    InvestorResultPopoverComponent,
    LoginComponent,
    MainActionsComponent,
    ModalAlertComponent,
    ModalAppStoresComponent,
    ModalBankRegisterResultComponent,
    ModalBeneficiaryComponent,
    ModalComponent,
    ModalConfirmationComponent,
    ModalContactCenterComponent,
    ModalContactComponent,
    ModalContactResultComponent,
    ModalFamilyBurdenComponent,
    ModalFileDownloadComponent,
    ModalFilterChipsComponent,
    ModalGenericComponent,
    ModalGetCertificateComponent,
    ModalInformationComponent,
    ModalMandateComponent,
    ModalIntroductoryComponent,
    ModalInvestmentTableFundComponent,
    ModalLoginComponent,
    ModalPowerAttorneyComponent,
    ModalStopScreenshareComponent,
    ModalPrimaryComponent,
    ModalStudiesCertificateComponent,
    ModalSummaryQuotaValuesComponent,
    ModalTransferPensionOptionComponent,
    ModalValidateCertificateComponent,
    MoreInformationComponent,
    MultiFundsAssignationComponent,
    MultiFundsProfitabilityComponent,
    MultiFundsRestrictionComponent,
    PaginatedTableComponent,
    PaperworkFollowUpComponent,
    PaymentMethodFormComponent,
    PaymentMethodStepComponent,
    PensionBackgroundsComponent,
    PersonBackgroundsComponent,
    PersonalInfoStepComponent,
    PensionModesComponent,
    PensionTypesComponent,
    PensionOffersComponent,
    PopoverComponent,
    PopoverHeaderMenuComponent,
    PopoverInformationComponent,
    PopoverOptionsComponent,
    QuotaValueSearcherComponent,
    QuotaValuesBannerComponent,
    QuotaValuesComponent,
    RadioButtonGroupComponent,
    RadioButtonListFormComponent,
    ReasonStepComponent,
    RegisterFormBoxComponent,
    SearchPaperworkByFieldComponent,
    SecondaryArticlesComponent,
    SerialNumberModalComponent,
    SkeletonComponent,
    SliderCalendarCardsComponent,
    SliderNavigationComponent,
    SlidesBannerComponent,
    SlidesComponent,
    SlidesInfographicComponent,
    SlidesProductsComponent,
    StateGuaranteeBackgroundComponent,
    StateGuaranteeConditionsComponent,
    StateGuaranteeDeclarationsComponent,
    StepperComponent,
    StepsComponent,
    SuperintendenceComponent,
    TabNavigationComponent,
    TransferPensionFormComponent,
    TransferPensionOptionComponent,
    TransferStateBoxComponent,
    ValidateCertificateBoxComponent,
    VoucherComponent,
    InfoTextPopupComponent,
    ModalDefinitionsComponent,
    DropDownPeriodComponent,
    AssistanceButtonComponent,
    AssistanceInHoursComponent,
    AssistanceOutHoursComponent,
    ModalContactCenterV2Component,
    MufflerComponent,
    ModalInfoDownloadableFormatsComponent,
    ModalRutValidationComponent,
    ModalTransferSurvivorPensionBeneficiariesComponent,
    AccountFoundsComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    IonicModule,
    MatMenuModule,
    MatSlideToggleModule,
    // BrowserAnimationsModule,
    MatButtonToggleModule,
    MatCardModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    NgxChartsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    AccessibilityComponent,
    AccordionDownloadListComponent,
    AccordionListComponent,
    AlertBannerComponent,
    AlertComponent,
    AlertToastComponent,
    ApplicationFormProgressComponent,
    AttachFilesComponent,
    AttachFilesPartialSaveComponent,
    AttachmentsPaymentComponent,
    AvatarComponent,
    BannerComponent,
    BreadcrumbComponent,
    CardCommentsComponent,
    CalendarCardComponent,
    CardComponent,
    CardArticleComponent,
    CardAttachedDocumentsComponent,
    CertifyStudiesBackgroundsComponent,
    CertifyStudiesDeclarationFileComponent,
    CertifyStudiesDeclarationsComponent,
    CertifyStudiesPaymentMethodComponent,
    CommentComponent,
    ContactChannelsComponent,
    ChargingFeesBackgroundsComponent,
    ChargingFeesDocumentationComponent,
    ChartInvestorProfileComponent,
    ChartQuotaValuesComponent,
    DeceasedAffiliateBackgroundComponent,
    DeceasedAffiliatePaymentComponent,
    DeceasedBackgroundFormComponent,
    DisclaimerBoxComponent,
    DisclaimerInfoComponent,
    DisclaimerCommisionComponent,
    DownloadSectionComponent,
    EmployerInfoStepComponent,
    FamilyAllowanceBackgroundComponent,
    FamilyAllowanceStatementComponent,
    FaqSectionComponent,
    FilterChipsComponent,
    FooterComponent,
    FormArrayListComponent,
    FormArrayListPartialSaveComponent,
    FormStatusDetailComponent,
    FundInfographicComponent,
    FundQuotaValueComponent,
    FuneralServicesBackgroundComponent,
    HeaderComponent,
    HeaderDesktopComponent,
    HeaderMobileComponent,
    HelpButtonComponent,
    IconCardComponent,
    InfoBannerComponent,
    InfoCardComponent,
    InfographicComponent,
    InformationAlertComponent,
    InfoTextPopupComponent,
    InheritanceRequestAttachmentsComponent,
    InheritanceRequestBackgroundsComponent,
    InheritanceRequestReasonsComponent,
    InvestorProfileBoxComponent,
    InvestorResultPopoverComponent,
    LoginComponent,
    MainActionsComponent,
    MoreInformationComponent,
    PaginatedTableComponent,
    PaperworkFollowUpComponent,
    PaymentMethodFormComponent,
    PaymentMethodStepComponent,
    PensionBackgroundsComponent,
    PersonBackgroundsComponent,
    PersonalInfoStepComponent,
    PensionModesComponent,
    PensionTypesComponent,
    PensionOffersComponent,
    QuotaValueSearcherComponent,
    QuotaValuesBannerComponent,
    QuotaValuesComponent,
    RadioButtonGroupComponent,
    RadioButtonListFormComponent,
    ReasonStepComponent,
    RegisterFormBoxComponent,
    SearchPaperworkByFieldComponent,
    SecondaryArticlesComponent,
    SkeletonComponent,
    SliderCalendarCardsComponent,
    SliderNavigationComponent,
    SlidesBannerComponent,
    SlidesComponent,
    SlidesInfographicComponent,
    SlidesProductsComponent,
    StateGuaranteeBackgroundComponent,
    StateGuaranteeConditionsComponent,
    StateGuaranteeDeclarationsComponent,
    StepperComponent,
    StepsComponent,
    SuperintendenceComponent,
    TabNavigationComponent,
    TransferPensionFormComponent,
    TransferPensionOptionComponent,
    TransferStateBoxComponent,
    ValidateCertificateBoxComponent,
    VoucherComponent,
    DropDownPeriodComponent,
    AssistanceButtonComponent,
    AssistanceInHoursComponent,
    AssistanceOutHoursComponent,
    ModalContactCenterV2Component,
    MufflerComponent,
    ModalAlertComponent,
    ModalGenericComponent,
    ModalRutValidationComponent,
    ModalTransferSurvivorPensionBeneficiariesComponent,
    AccountFoundsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
